<template>
  <div class="container-redirect">
    <div class="message-to-redirect">
      <app-heading level="h6">{{$t('redirect')}}:</app-heading>
      <p>{{message.redirectUrl}}</p>
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'redirect',
  props: ['message'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  }
}
</script>
<style lang="scss">
.container-redirect {
  min-width: 60%;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .message-to-redirect{
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      text-align: center;
      margin: 0 0 5px 0;
      font-size: 15px !important;
      color: var(--splashColor);
    }
    p {
      word-wrap: break-word;
      font-size: 14px;
      color: var(--splashColor);
    }
  }
}
.spinner {
  margin: 10px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 15px;
  height: 15px;
  background: var(--headerBg);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  margin-right: 5px;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner .bounce3 {
  margin-left: 5px;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
